export const en = {
  auth: {
    logout: "Logout",
    language: "Language",
    login: "Go to login screen",
    logoutTitle: "Your session is expired.",
    logoutDescription: "You will be moved to login screen automatically in 5 seconds or you can click the button to move immediately.",
    en: "English",
    he: "Hebrew"
  },
  layout: {
    organization: "Organization",
    chooseEnv: "Choose environment",
    chooseSystem: "Choose system",
    rStop: "RStop",
    rStopTip: "Stops the engines of the system when enabled",
    rStopEnableWarn: "Are you sure you want to activate RStop?",
    rStopDisableWarn: "Are you sure you want to disable RStop?",
    rStopEnable: "Enable",
    rStopDisable: "Disable",
    rStopEnabled: "Enabled",
    rStopDisabled: "Disabled",
    sure: "Are you sure?",
    search: "Search",
    system: "System",
    activate: "Activate",
    confirm: "Confirm",
    cancel: "Cancel",
    submit: "Submit",
    home: "Home",
    control: "Control",
    orders: "Orders",
    metadata: "Metadata Entries",
    monitoring: "Monitoring",
    replenishment: "Replenishment",
    transfers: "Transfers Data",
    keys: "Keys",
    indexing: "Indexing requests",
    streams: "Live Streams",
    library: "Library",
    store: "Store",
    support: "Support",
    warranty: "Warranty",
    "products-portfolio": "Products Portfolio",
    orderDetail: "Order Detail",
    tableRows: "Rows per page",
    portfolio: "Products Portfolio",
    uploadTitle: "Upload image",
    uploadRule: "Max file size: {{size}}MB",
    uploadTip: "Browse",
    pleaseWait: "Please wait",
    fileSizeError: "Image size exceeds {{size}} MB. Please select a smaller image."
  },
  navigation: {
    home: "Home",
    orders: "Orders",
    maintenance: "Maintenance",
    replenishment: "Replenishment",
    transfers: "Transfers Data",
    indexing: "Indexing",
    system: "System",
    store: "Store",
    monitoring: "Monitoring",
    stream: "Live stream",
    mediaLibrary: "Library",
    keys: "Keys",
    productsPortfolio: "Products Portfolio",
    control: "Control",
    metadata: "Metadata entries"
  },
  systemTasks: {
    title: "System-enabled tasks",
    Orders: "Order",
    orders: "Order",
    Maintenance: "Maintenance",
    Deliveries: "Deliveries",
    Fulfillment: "Fulfillment",
    Consolidation: "Consolidation",
    action: "Change",
    apply: "Apply",
    empty: "Nothing selected",
  },
  systemMode: {
    titleActive: "Auto mode",
    titleInactive: "Manual mode",
    subtitle: "System working automatically",
    action: "Control",
  },
  acceptNewOrders: {
    title: "Accept new orders",
    subtitleActive: "New orders will be accepted",
    subtitleInactive: "New orders will be rejected",
    enabled: "Enabled",
    disabled: "Disabled",
    confirm: "Confirm",
    confirmationTitle: "Change status",
    confirmationDescription:
      "Are you sure you want to change the accept new orders status?",
  },
  systemActivityGraph: {
    title: "Analytics",
    orderActivities: "Orders",
    reservationActivities: "Reservations",
    validationActivities: "Validations",
    customRange: "Custom range",
    today: "Today",
    day: "24 hours",
    week: "Week",
    month: "Month",
    year: "Year",
    quarter: "Quarter",
    custom: "Custom range",
    total: "Total",
    failed: "Failed",
    rangeWarn: "The date range cannot be more than",
    rangeWarnAmount: "2 years",
  },
  machineSpeed: {
    title: "Machine speed",
    subtitle: "Adjust machine speed",
    low: "Slow",
    medium: "Medium",
    fast: "Fast",
    confirmationTitle: "Change speed",
    confirmationDescription:
      "Are you sure you want the change the machine speed?",
    confirm: "Confirm",
  },
  healthy: {
    titleActive: "Healthy",
    titleInactive: "Unhealthy",
    subtitleActive: "System working correctly",
    subtitleInactive: "System has issues",
    actionActive: "No actions needed",
    actionInactive: "Change to Healthy",
    confirm: "Confirm",
    confirmationTitle: "Change status",
    confirmationDescription:
      "Are you sure you want to change the healthy status?",
    healthUpdateError: "Error while updating system health",
    healthUpdateSuccess: "System successfully set to healthy",
  },
  systems: {
    estop: "RStop",
    hours: "hours",
    days: "days",
    title: "System",
    lastUpdated: "Last updated: {{time}} ago",
    open: "Open",
    closed: "Closed",
    confirmation: "Are you sure?",
    active: "Active",
    inactive: "Deactivated",
    ago: "ago",
    summary: "Summary",
    pallets: "Pallets",
    emptyPallets: "Empty pallets",
    temperature: "Temperature",
    packerStock: "Packer Stock",
    monitor: "Monitor",
    keys: "Keys",
    yes: "Yes",
    unavailable: "Currently unavailable",
    buffers: "Buffers",
    mode: "System Mode",
    pickup: "Pickup",
    activity: "Activity",
    containerState: "Container state",
    activeTasksTitle: "Enabled system tasks",
    activeTasksDescription: "Tasks the system performs",
    maintenance: "Maintenance",
    collection: "Packs collection",
    newOrdersTitle: "Receive new orders",
    newOrdersDescription: "System receives new orders from remote clients",
    availability: "Availability",
    orders: "Orders",
    reservations: "Reservations",
    validations: "Validations",
    machineSpeed: "Machine speed",
    Low: "Low",
    Medium: "Medium",
    Fast: "Fast",
    compressor: "Compressor",
    on: "On",
    off: "Off",
    notAvailable: "Not available",
  },
  monitors: {
    time: "Time",
    severity: "Severity",
    alert: "Alert",
    title: "Monitors",
  },
  keys: {
    title: "Keys",
    id: "Key Id",
    created: "Date created",
    name: "Name",
    phone: "Phone Number",
    level: "Level",
    active: "Active",
    status: "Status",
    inactive: "Inactive",
    code: "Qr Code",
    privilege: "Authorization level",
    privileges: "Privileges",
    privilegeError: "Please select an authorization level",
    type: "Key type",
    typeError: "Please select a key type",
    replenishmentRule: "Allows replenishment operations",
    fullRule: "Allows full machinery deactivation",
    debugRule: "Allows machinery debug",
    create: "Create New Key",
    cancel: "Cancel",
    basic: "Basic",
    safeMode: "Safe Mode",
    jogMode: "Jog Mode",
    oneTime: "One Time",
    reusable: "Reusable",
    pdfTitle: "Container key",
    createDrawerTitle: "Create new key",
    createKeyButton: "Create new key +",
    createSuccessMessage: "Key successfully created!",
    createErrorMessage: "Error while creating key!",
    key: "Key",
    keys: "Keys",
    createdBy: "Created by",
    SafeMode: "Safe mode",
    JogMode: "Jog mode",
    InsertPallets: "Insert pallets",
    ExtractStockPallets: "Extract stock pallets",
    ExtractBufferPallets: "Extract buffer pallets",
    ExtractMachinePallets: "Extract machine pallets",
    createFormTitle: "Create New Key",
    createFormName: "Key name",
    createFormNamePlaceholder: "Enter key name",
    createFormPrivileges: "Privileges",
    createFormPrivilegesPlaceholder: "Please select",
    createFormType: "Key type",
    pleaseSelect: "Please select",
    OneTime: "One-time Use",
    Reusable: "Reusable",
    OneTimeDescription: "Deactivates after one use. Can be reactivated manually",
    ReusableDescription: "Always active, can be deactivated manually",
    required: "Field is required",
    createFormPhone: "Phone attached",
    detailsCreatedBy: "Created By",
    detailsDateCreated: "Date Created",
    detailsStatus: "Current Status",
    detailsPhone: "Phone Number",
    detailsType: "Key type",
    detailsPrivileges: "Privileges",
    detailsHistory: "Usage history",
    detailsId: "Key ID",
    filtersSearch: "Search by ID or Name",
    filterStatus: "All statuses",
    filterPrivileges: "All privileges",
    resetFilters: "Reset Filters",
    system: "System"
  },
  stock: {
    title: "Stock",
    pallet: "Pallet",
    loadedAt: "Loaded at",
    markForMaintenance: "Mark for maintenance",
    totalProducts: "Total products",
    name: "Name",
    sku: "SKU",
    quantity: "Quantity",
    shop: "Shop",
    cart: "Cart",
    clear: "Clear All",
    empty: "Cart Is Empty",
    total: "Total",
    order: "Order Now",
    orderFail: "Order creation failed",
    orderSuccessTitle: "Yayyy!",
    orderSuccessDescription: "Order number {{id}} created successfully",
    searchPlaceholder: "Search products by name",
  },
  orders: {
    id: "id",
    time: "date & time",
    source: "Source",
    packs: "# of packs",
    status: "order status",
    delivery: "delivery",
    total: "Total orders",
    selectStatuses: "Select statuses",
    idPlaceholder: "Search by ID",
    searchPlaceholder: "Search by details",
    searchHint: "Type at least 2 symbols",
    statusPlaceholder: "Select statuses",
    resetFilters: "Reset Filters",
    table: "Table",
    board: "Board",
    noOrders: "No orders to show",
    export: "Export to CSV",
    parts: "Parts",
    api: "API",
    user: "User",
    processMany: "Process Selected Orders",
    selectedOrder: "1 Order Selected",
    selectedOrders: "{{amount}} Orders Selected",
    processSuccess: "Orders processed successfully",
    processPartiallySuccess: "Some orders not processed, please try again",
    processError: "Failed to process orders",
    search: "Search",
    clear: "Clear"
  },
  orderBoard: {
    order: "Order",
    delivery: "Delivery",
    packs: "Packs",
    parts: "Parts",
    part: "Part",
    user: "User",
  },
  order: {
    details: "Details",
    id: "Order ID",
    packs: "Packs",
    code: "Order picking code",
    operations: "Operations",
    timeline: "Timeline",
    name: "Name",
    productName: "Product name",
    packId: "Pack id",
    sku: "SKU",
    quantity: "QTY",
    collectedQuantity: "Collected QTY",
    pickup: "Pickup manually",
    collect: "Collect manually",
    shipping: "Retry shipping",
    totalProducts: "Total Products",
    DeliveryRequested: "Delivery Requested",
    PartialPacked: "Partial Packed",
    Pending: "Pending",
    Approved: "Approved",
    InProgress: "In Progress",
    Packed: "Packed",
    Queued: "Queued",
    OutOfStock: "Out Of Stock",
    PackedManually: "Packed (M)",
    Delivering: "Delivering",
    CollectedManually: "Collected (M)",
    Collected: "Collected",
    Canceled: "Canceled",
    Failed: "Failed",
    Paused: "Paused",
    PackingFailed: "Packing Failed",
    DeliveryFailed: "Delivery Failed",
    ProcessingFailed: "Processing Failed",
    PartialCollected: "Partially Collected",
    CollectionFailed: "Collection Failed",
    Shipped: "Shipped",
    Archived: "Archived",
    ArchiveRequested: "Archive Requested",
    noDelivery: "No Delivery",
    RetryOrder: "Retry",
    RetryShipping: "Retry",
    ManualPickUp: "Manual pick up",
    ManualDelivery: "Manual delivery",
    NotifyFailure: "Notify failure",
    pickUpSuccess: "Manual pick up was set successfully",
    orderSuccess: "Order recreated successfully",
    shipmentSuccess: "Delivery recreated successfully",
    deliverySuccess: "Manual delivery was set successfully",
    failureSuccess: "Failure was notified successfully",
    cancelSuccess: "Order was successfully canceled",
    queueSuccess: "Order was successfully queued",
    completeSuccess: "Order was successfully completed",
    operationFail: "Operation failed",
    cancel: "Cancel",
    process: "Process Order",
    complete: "Complete",
    submit: "Submit",
    part: "Order part",
    products: "Products",
    source: "Source",
    api: "API",
    user: "User",
    confirmProcess: "Are you sure you want to process order?",
    confirmCancel: "Are you sure you want to cancel order?",
    confirmComplete: "Are you sure you want to complete order?",
    confirmManualDelivery: "Are you sure you want to apply a Manual Delivery?",
  },
  shipping: {
    retryShipping: "Retry shipping",
    externalId: "Company external ID",
    delivery: "Delivery",
    verification: "Verification code",
    cancel: "Cancel",
    submit: "Submit",
    name: "Name",
    enterName: "Enter name",
    address: "Address",
    enterAddress: "Enter address",
    notes: "Notes",
    enterNotes: "Enter notes",
    phone: "Phone number",
    retryShipmentSuccess: "Delivery recreated successfully",
    operationFail: "Operation failed",
    shipmentTitle: "Shipment details",
    completed: "Delivered",
    verificationCode: "Verification Code",
    showLogs: "Show Logs",
    noShipment: "No Shipment",
    attempts: "Number of attempts",
    orderOperationsTitle: "Order operations",
    shipmentOperationsTitle: "Shipment operations",
    noOperations: "There are no available operations at the moment",
    retryOrderTitle: "Are you sure you want to retry the order?",
    manualDeliveryTitle: "Are you sure you want to deliver manually?",
    manualPickupTitle: "Are you sure you want to pick the order manually?",
    notifyFailureTitle: "Are you sure you want to notify failure?",
    retryOrderDescription: "You will not be able to undo this operation",
    manualDeliveryDescription: "You will not be able to undo this operation",
    manualPickupDescription: "You will not be able to undo this operation",
    notifyFailureDescription: "It will cancel all operations",
    retryOrderOkText: "Retry",
    manualDeliveryOkText: "Deliver Manually",
    manualPickupOkText: "Pickup Manually",
    notifyFailureOkText: "Notify",
    shipmentDetails: "Shipment Details",
    retry: "Retry",
  },
  transfers: {
    title: "Transfers Data",
    id: "Id",
    type: "Type",
    quantity: "Quantity",
    created: "Created",
    name: "Name",
    sku: "SKU",
    searchPlaceholder: "Enter transfer product id to see details",
    notFoundError: "Transfer product with id {{id}} does not exist",
  },
  maintenance: {
    id: "Id",
    key: "Key",
    in: "In",
    out: "Out",
    started: "Started",
    ended: "Ended",
    title: "Replenishment",
    name: "Name",
    sku: "SKU",
    diff: "Diff",
    operation: "Operation",
    operations: "Operations",
    indexing: "Indexing",
    palletId: "Pallet ID",
    transferId: "Transfer ID",
    quantity: "Quantity",
    compareTitle: "Stock Compare",
    report: "Download Report",
  },
  media: {
    front: "Front",
    back: "Back",
    kiosk: "Kiosk",
    lift: "Lift",
    outdoor1: "Outdoor 1",
    outdoor2: "Outdoor 2",
    title: "Live Streams",
    hlsNotSupported: "This browser does not support live streaming",
    id: "Id",
    from: "From",
    to: "To",
    mediaType: "Type",
    status: "Status",
    user: "User",
    progress: "Progress",
    count: "Count",
    libraryTitle: "Media Library",
    createNew: "New +",
    createDrawerTitle: "Create new media",
    createKeyButton: "Create new media +",
    createSuccessTitle: "Yayyy!",
    createSuccessMessage: "Media successfully created!",
    mediaTypeError: "Please select a media type",
    timeRangeError: "Date time range should not be more than 5 minutes",
    dateError: "Please select a date range",
    create: "Create",
    cancel: "Cancel",
    date: "Date",
    Images: "Images",
    Videos: "Videos",
    Pending: "Pending",
    Processing: "Processing",
    Uploading: "Uploading",
    Success: "Success",
    Failed: "Failed",
    streamsTip: "You can select up to 2 cameras",
  },
  indexing: {
    id: "Id",
    trigger: "Trigger",
    status: "Status",
    createdOn: "Created",
    listTitle: "Indexing requests",
    create: "Create",
    createTitle: "Create indexing request",
    maintenanceId: "Maintenance ID",
    maintenancePlaceholder: "Select Maintenance",
    pallet: "Pallet",
    palletPlaceholder: "Select Pallet",
    side: "Side",
    range: "Range",
    confirm: "Confirm",
    cancel: "Cancel",
    remove: "Remove",
    createSuccessTitle: "Success",
    createSuccessMessage: "Indexing request successfully created",
    pending: "Pending",
    approved: "Approved",
    inprogress: "In Progress",
    completed: "Completed",
    failed: "Failed",
    createFail: "There was an error while creating an indexing request",
    compareTitle: "Compare indexing results",
    filterByPallet: "Filter by Pallet ID",
    palletIdPlaceholder: "Select Pallet ID",
    palletId: "Pallet ID",
    cellID: "Cell ID",
    row: "Row",
    column: "Column",
    sku: "SKU",
    quantity: "Quantity",
    validity: "Validity",
    error: "Error",
    valid: "Valid",
    invalid: "Invalid",
    totalQuantity: "Total Quantity",
    rangesTitle: "Indexing ranges",
    start: "Start",
    end: "End",
    wholeSystemIndexing: "Indexing the whole system",
    delete: "Delete",
    createRange: "Add range",
    rangeTitle: "Adding new range",
    ranges: "Ranges",
    rangeOne: "range",
    rangeMore: "ranges",
    rangeTip: "will be added in the indexing request",
    rangeTipNoRanges: "Please add at least one range",
  },
  system: {
    stations: "Stations",
    packerStock: "Packer stock",
    temperature: "Temperature",
    buffers: "Buffers",
    compressor: "Compressor",
    on: "On",
    off: "Off",
    searchPlaceholder: "Search by Name or SKU",
    name: "Name",
    sku: "SKU",
    totalQuantity: "total",
    availableQuantity: "available",
    assignedQuantity: "assigned",
    reservedQuantity: "reserved",
    enabled: "Enabled",
    total: "Total products",
    id: "ID",
    station: "Station",
    quantity: "QTY",
    stationTotalProducts: "Total",
    warning: "The system information is currently unavailable",
    buffer: "Buffer",
    export: "Export to CSV"
  },
  upsState: {
    ratinVoltage: "Ratin voltage",
    ratoutVoltage: "Ratout voltage",
    battery: "Battery",
    temperature: "Temperature",
    source: "Out Source",
    warning: "The ups information is currently unavailable"
  },
  productsPortfolio: {
    products: "Products",
    name: "Name",
    category: "Category",
    categoryName: "Category name",
    sku: "SKU",
    disabledSystems: "Disabled Systems",
    searchPlaceholder: "Search by Name or SKU",
    categoriesPlaceholder: "All Categories",
    disabledSystemsFilter: "Only products with disabled systems",
    resetFilters: "Reset filters",
    productName: "Product name",
    productImage: "Product image",
    temperature: "Temperature",
    frozen: "Frozen",
    fresh: "Fresh",
    chilled: "Chilled",
    cold: "Cold",
    ambient: "Ambient",
    none: "None",
    external: "External",
    properties: "Properties",
    weight: "Weight",
    size: "Size",
    small: "Small",
    medium: "Medium",
    big: "Big",
    create: "Create",
    namePlaceholder: "Enter product name",
    categoryNamePlaceholder: "Enter category name",
    dropdownPlaceholder: "Please select",
    createTitle: "Create New Product",
    createProductButton: "New Product",
    requiredMessage: "This field is required",
    createProductSuccess: "Product created successfully",
    createProductFail: "Error while creating product",
    updateProductSuccess: "Product updated successfully",
    updateProductFail: "Error while updating product",
    approveProductSuccess: "Product approved successfully",
    approveProductFail: "Error while approving product",
    revertProductSuccess: "Product reverted successfully",
    revertProductFail: "Error while reverting product",
    approveProductsSuccess: "Products approved successfully",
    approveProductsFail: "Error while approving products",
    declineProductSuccess: "Product declined successfully",
    declineProductFail: "Error while declining product",
    optional: "(Optional)",
    nameSuccess: "Product name updated successfully",
    nameError: "Error while updating product name",
    categorySuccess: "Product category updated successfully",
    categoryError: "Error while updating product category",
    createCategoryError: "Error while creating product category",
    noCategory: "No category",
    skusTitle: "Product SKUs",
    addSku: "Add SKU",
    createCategory: "Create new Category",
    skuNamePlaceholder: "Enter SKU name",
    barCodes: "Barcodes",
    barCodesPlaceholder: "Enter barcodes",
    barCodesAddPlaceholder: "Enter barcode to add",
    barCodesTip: "Separate multiple barcodes by a comma",
    cancel: "Cancel",
    save: "Save",
    addSkuSuccess: "SKU successfully added to the product",
    addSkuError: "Error while adding sku to the product",
    addBarcodeSuccess: "Barcode successfully added to the sku",
    addBarcodeError: "Error while adding barcode to the sku",
    emptyBarcodeError: "Barcode should not be empty",
    more: "more",
    status: "Status",
    pending: "Pending",
    approved: "Approved",
    declined: "Declined",
    approvePendingProducts: "Approve Pending Products",
    details: "Details",
    productSkus: "Product SKUs",
    externalLabel: "External storage",
    enabled: "Enabled",
    saveChanges: "Save changes",
    declineProduct: "Decline Product",
    approveProduct: "Approve Product",
    revertProduct: "Revert Product",
    statusesPlaceholder: "All Statuses",
    confirmation: "Are you sure?",
    export: "Export to CSV",
  },
  monitoring: {
    userPlaceholder: "Search by user",
    statusPlaceholder: "All statuses",
    typePlaceholder: "All types",
    resetFilters: "Reset filters",
    library: "Media Library",
    streams: "Live Stream",
    images: "Image",
    videos: "Video",
    Pending: 'Pending',
    Processing: 'Processing',
    Uploading: 'Uploading',
    Success: "Success",
    Failed: "Failed",
    date: "Date",
    type: "Type",
    status: "Status",
    user: "User",
    total: "Total",
    cancel: "Cancel",
    request: "Request",
    addMedia: "Add media",
    requestMedia: "Request media",
    chooseCamera: "Choose camera",
    chooseCameraTip: "(Up to 2)",
    minute: "minute",
    minutes: "minutes",
    duration: "Duration",
    time: "Time",
    details: "Details of request",
    detailsPlaceholder: "Why you request this specific data",
    typePlaceholderForm: "Select type",
    durationPlaceholderForm: "Select duration",
    requestTitle: "Request media",
    media: "Media",
    requiredError: "Field is required",
    requestsSuccess: "requests were successfully added",
    requestSuccess: "request were successfully added",
    requestError: "Error while adding requests",
    front: "Front",
    back: "Back",
    kiosk: "Kiosk",
    lift: "Lift",
    "outdoor-1": "Outdoor 1",
    "outdoor-2": "Outdoor 2",
    "ceiling-back": "Ceiling back",
    "ceiling-front": "Ceiling front",
    "ceiling-1": "Ceiling 1",
    "ceiling-2": "Ceiling 2",
    "ceiling-3": "Ceiling 3",
    "ceiling-4": "Ceiling 4",
    "boxer": "Boxer",
    "outdoor": "Outdoor",
    hlsNotSupported: "This browser does not support live streaming",
    modalTitle: "Select Media",
    modalSubtitleVideo: "Click on the video to view it in full size",
    modalSubtitleImage: "Click on the image to view it in full size",
    preview: "Preview",
    mediaPreview: "Media Preview",
    noImages: "No images to show",
    noVideos: "No videos to show",
  },
  store: {
    searchPlaceholder: "Search by Name",
    cart: "Cart",
    resetFilters: "Reset Filters",
    name: "Name",
    totalQuantity: "total",
    availableQuantity: "available",
    assignedQuantity: "assigned",
    reservedQuantity: "reserved",
    sku: "SKU",
    quantity: "QTY",
    add: "Add to cart",
    total: "Total",
    clear: "Clear all",
    create: "Create an order",
    placeOrderSuccess: "Order created successfully",
    placeOrderError: "Error while creating order",
  },
  control: {
    autoMode: "Auto mode",
    manualMode: "Manual mode",
    switchToAuto: "Switch to Auto Mode", 
    switchToManual: "Switch to Manual mode", 
    searchPlaceholder: "Search by name",
    stream: "Live Stream",
    run: "Run",
    manualConfirmation: "Are you sure you want to take control?",
    confirm: "Confirm",
    warning: "You take control",
    error: "Issue while switching mode",
    success: "Mode switched to auto",
    autoModeEnabled: "Auto mode enabled",
    commandSuccess: "Command run successfully",
    commandError: "Error running command",
  },
  support: {
    support: "Support",
    pageTip: "Please provide the information regarding your case below, and our support team will assist you as soon as possible.",
    subject: "Subject",
    subjectPlaceholder: "Enter subject of your case",
    description: "Description",
    descriptionPlaceholder: "Describe details",
    submit: "Submit",
    successTitle: "Case submitted successfully!",
    successDescription: "The Support team will assist you as soon as possible. For further assistance you can find the ticket details in your email.",
    close: "Close",
    modalDescription: "Please provide the information regarding your case below.",
    cancel: "Cancel",
  },
  metadata: {
    filterName: "Search by Name",
    filterRequired: "Show only required",
    filterClear: "Clear filter",
    entries: "Entries",
    name: "Name",
    options: "Options",
    type: "Type",
    required: "Required",
    edit: "Edit",
    delete: "Delete",
    Int: "Integer",
    Bool: "Bool",
    String: "String",
    Float: "Float",
    addNew: "Add New Entry",
    namePlaceholder: "Enter Name",
    typePlaceholder: "Enter Type",
    optionsPlaceholder: "Add Options, comma separated",
    requiredLabel: "Mark as required",
    cancel: "Cancel",
    add: "Add Entry",
    editDrawerTitle: "Edit Metadata",
    save: "Save changes",
    createSuccessMessage: "Metadata entry successfully created!",
    createErrorMessage: "Error while creating metadata entry!",
    updateSuccessMessage: "Metadata entry successfully updated!",
    updateErrorMessage: "Error while updating metadata entry!",
    deleteSuccessMessage: "Metadata entry successfully deleted!",
    deleteErrorMessage: "Error while deleting metadata entry!",
    confirmationTitle: "Confirm deletion",
    deletionConfirmation: "Are you sure you want to delete metadata entry?",
    confirm: "Confirm",
    requiredMessage: "This field is required",
  }
};
