export const he = {
  auth: {
    logout: "התנתקות",
    language: "Language",
    login: "מעבר למסך ההתחברות",
    logoutTitle: "תוקף החיבור שלך חלף.",
    logoutDescription: "תועבר למסך ההתחברות באופן אוטומטי תוך 5 שניות או שתוכל ללחוץ על הכפתור כדי לעבור מיידית."
  },
  layout: {
    organization: "ארגון",
    chooseEnv: "בחר סביבה",
    chooseSystem: "בחר מערכת",
    rStop: "RStop",
    rStopTip: "עוצר את מנועי המערכת כאשר מופעל",
    rStopEnableWarn: "האם אתה בטוח שברצונך להפעיל את RStop?",
    rStopDisableWarn: "האם אתה בטוח שברצונך להשבית את RStop?",
    rStopEnable: "הפעל",
    rStopDisable: "השבת",
    rStopEnabled: "מופעל",
    rStopDisabled: "מושבת",
    sure: "האם אתה בטוח?",
    search: "חיפוש",
    system: "מערכת",
    activate: "הפעל",
    confirm: "אשר",
    cancel: "בטל",
    submit: "שלח",
    home: "בית",
    control: "שליטה",
    orders: "הזמנות",
    metadata: "רישומי מטא נתונים",
    monitoring: "ניטור",
    replenishment: "חידוש מלאי",
    transfers: "נתוני העברות",
    keys: "מפתחות",
    indexing: "בקשות אינדוקס",
    streams: "שידורים חיים",
    library: "ספריה",
    store: "חנות",
    support: "תמיכה",
    warranty: "אחריות",
    "products-portfolio": "פורטפוליו מוצרים",
    orderDetail: "פרטי הזמנה",
    tableRows: "שורות בעמוד",
    portfolio: "פורטפוליו מוצרים",
    uploadTitle: "Upload image",
    uploadRule: "Max file size: {{size}}MB",
    uploadTip: "Browse",
    pleaseWait: "Please wait",
    fileSizeError: "Image size exceeds {{size}} MB. Please select a smaller image."
  },
  navigation: {
    home: "בית",
    orders: "הזמנות",
    maintenance: "תחזוקה",
    replenishment: "חידוש מלאי",
    transfers: "נתוני העברות",
    indexing: "אינדוקס",
    system: "מערכת",
    store: "חנות",
    monitoring: "ניטור",
    stream: "שידור חי",
    mediaLibrary: "ספריית מדיה",
    keys: "מפתחות",
    productsPortfolio: "פורטפוליו מוצרים",
    control: "שליטה",
    metadata: "מטא נתונים"
  },
  systemTasks: {
    title: "משימות מופעלות מערכת",
    Orders: "הזמנה",
    orders: "הזמנה",
    Maintenance: "תחזוקה",
    Deliveries: "משלוחים",
    Fulfillment: "מימוש",
    Consolidation: "איחוד",
    action: "שנה",
    apply: "החל",
    empty: "שום דבר לא נבחר"
  },
  systemMode: {
    titleActive: "מצב אוטומטי",
    titleInactive: "מצב ידני",
    subtitle: "המערכת פועלת אוטומטית",
    action: "שליטה"
  },
  acceptNewOrders: {
    title: "קבלת הזמנות חדשות",
    subtitleActive: "הזמנות חדשות יתקבלו",
    subtitleInactive: "הזמנות חדשות יידחו",
    enabled: "מופעל",
    disabled: "מושבת",
    confirm: "אשר",
    confirmationTitle: "שינוי סטטוס",
    confirmationDescription: "האם אתה בטוח שברצונך לשנות את סטטוס קבלת ההזמנות החדשות?"
  },
  systemActivityGraph: {
    title: "אנליטיקה",
    orderActivities: "הזמנות",
    reservationActivities: "הזמנות מראש",
    validationActivities: "אישורים",
    customRange: "טווח מותאם אישית",
    today: "היום",
    day: "24 שעות",
    week: "שבוע",
    month: "חודש",
    year: "שנה",
    quarter: "רבעון",
    custom: "טווח מותאם אישית",
    total: "סה\"כ",
    failed: "נכשל",
    rangeWarn: "טווח התאריכים לא יכול להיות יותר מ-",
    rangeWarnAmount: "שנתיים"
  },
  machineSpeed: {
    title: "מהירות מכונה",
    subtitle: "התאם מהירות מכונה",
    low: "איטי",
    medium: "בינוני",
    fast: "מהיר",
    confirmationTitle: "שנה מהירות",
    confirmationDescription: "האם אתה בטוח שברצונך לשנות את מהירות המכונה?",
    confirm: "אשר"
  },
  healthy: {
    titleActive: "תקין",
    titleInactive: "לא תקין",
    subtitleActive: "המערכת פועלת כראוי",
    subtitleInactive: "למערכת יש בעיות",
    actionActive: "אין צורך בפעולות",
    actionInactive: "שנה למצב תקין",
    confirm: "אשר",
    confirmationTitle: "שינוי סטטוס",
    confirmationDescription: "האם אתה בטוח שברצונך לשנות את הסטטוס לתקין?",
    healthUpdateError: "שגיאה בעדכון מצב הבריאות של המערכת",
    healthUpdateSuccess: "המערכת הוגדרה בהצלחה כתקינה"
  },
  systems: {
    estop: "RStop",
    hours: "שעות",
    days: "ימים",
    title: "מערכת",
    lastUpdated: "עודכן לאחרונה: לפני {{time}}",
    open: "פתוח",
    closed: "סגור",
    confirmation: "האם אתה בטוח?",
    active: "פעיל",
    inactive: "מושבת",
    ago: "לפני",
    summary: "סיכום",
    pallets: "משטחים",
    emptyPallets: "משטחים ריקים",
    temperature: "טמפרטורה",
    packerStock: "מלאי אורזים",
    monitor: "ניטור",
    keys: "מפתחות",
    yes: "כן",
    unavailable: "לא זמין כרגע",
    buffers: "מאגרי זיכרון",
    mode: "מצב מערכת",
    pickup: "איסוף",
    activity: "פעילות",
    containerState: "מצב מכולה",
    activeTasksTitle: "משימות מערכת מופעלות",
    activeTasksDescription: "משימות שהמערכת מבצעת",
    maintenance: "תחזוקה",
    collection: "איסוף חבילות",
    newOrdersTitle: "קבלת הזמנות חדשות",
    newOrdersDescription: "המערכת מקבלת הזמנות חדשות מלקוחות מרוחקים",
    availability: "זמינות",
    orders: "הזמנות",
    reservations: "הזמנות מראש",
    validations: "אישורים",
    machineSpeed: "מהירות מכונה",
    Low: "נמוכה",
    Medium: "בינונית",
    Fast: "מהירה",
    compressor: "מדחס",
    on: "מופעל",
    off: "כבוי",
    notAvailable: "לא זמין"
  },
  monitors: {
    time: "זמן",
    severity: "חומרה",
    alert: "התראה",
    title: "ניטור"
  },
  keys: {
    title: "מפתחות",
    id: "מזהה מפתח",
    created: "תאריך יצירה",
    name: "שם",
    phone: "מספר טלפון",
    level: "רמה",
    active: "פעיל",
    status: "סטטוס",
    inactive: "לא פעיל",
    code: "קוד QR",
    privilege: "רמת הרשאה",
    privileges: "הרשאות",
    privilegeError: "נא לבחור רמת הרשאה",
    type: "סוג מפתח",
    typeError: "נא לבחור סוג מפתח",
    replenishmentRule: "מאפשר פעולות חידוש מלאי",
    fullRule: "מאפשר השבתה מלאה של המערכת",
    debugRule: "מאפשר דיבוג מכונות",
    create: "צור מפתח חדש",
    cancel: "בטל",
    basic: "בסיסי",
    safeMode: "מצב בטוח",
    jogMode: "מצב ג'וג",
    oneTime: "חד-פעמי",
    reusable: "ניתן לשימוש חוזר",
    pdfTitle: "מפתח מכולה",
    createDrawerTitle: "צור מפתח חדש",
    createKeyButton: "צור מפתח חדש +",
    createSuccessMessage: "המפתח נוצר בהצלחה!",
    createErrorMessage: "שגיאה ביצירת המפתח!",
    key: "מפתח",
    keys: "מפתחות",
    createdBy: "נוצר על ידי",
    SafeMode: "מצב בטוח",
    JogMode: "מצב ג'וג",
    InsertPallets: "הכנסת משטחים",
    ExtractStockPallets: "הוצאת משטחי מלאי",
    ExtractBufferPallets: "הוצאת משטחי מאגר",
    ExtractMachinePallets: "הוצאת משטחי מכונה",
    createFormTitle: "צור מפתח חדש",
    createFormName: "שם המפתח",
    createFormNamePlaceholder: "הזן שם מפתח",
    createFormPrivileges: "הרשאות",
    createFormPrivilegesPlaceholder: "נא לבחור",
    createFormType: "סוג מפתח",
    pleaseSelect: "נא לבחור",
    OneTime: "שימוש חד-פעמי",
    Reusable: "ניתן לשימוש חוזר",
    OneTimeDescription: "מושבת לאחר שימוש אחד. ניתן להפעיל מחדש ידנית",
    ReusableDescription: "פעיל תמיד, ניתן להשבית ידנית",
    required: "שדה חובה",
    createFormPhone: "טלפון מצורף",
    detailsCreatedBy: "נוצר על ידי",
    detailsDateCreated: "תאריך יצירה",
    detailsStatus: "סטטוס נוכחי",
    detailsPhone: "מספר טלפון",
    detailsType: "סוג מפתח",
    detailsPrivileges: "הרשאות",
    detailsHistory: "היסטוריית שימוש",
    detailsId: "מזהה מפתח",
    filtersSearch: "חפש לפי מזהה או שם",
    filterStatus: "כל הסטטוסים",
    filterPrivileges: "כל ההרשאות",
    resetFilters: "אפס מסננים",
    system: "מערכת"
  },
  stock: {
    title: "מלאי",
    pallet: "משטח",
    loadedAt: "נטען ב",
    markForMaintenance: "סמן לתחזוקה",
    totalProducts: "סה\"כ מוצרים",
    name: "שם",
    sku: "מזהה מוצר (SKU)",
    quantity: "כמות",
    shop: "חנות",
    cart: "עגלת קניות",
    clear: "נקה הכל",
    empty: "העגלה ריקה",
    total: "סה\"כ",
    order: "הזמן עכשיו",
    orderFail: "יצירת ההזמנה נכשלה",
    orderSuccessTitle: "יששש!",
    orderSuccessDescription: "הזמנה מספר {{id}} נוצרה בהצלחה",
    searchPlaceholder: "חפש מוצרים לפי שם"
  },
  orders: {
    id: "מזהה",
    time: "תאריך ושעה",
    source: "מקור",
    packs: "מספר חבילות",
    status: "סטטוס הזמנה",
    delivery: "משלוח",
    total: "סה\"כ הזמנות",
    selectStatuses: "בחר סטטוסים",
    searchPlaceholder: "חפש לפי פרטים",
    idPlaceholder: "חפש לפי מזהה",
    searchHint: "הקלד לפחות 2 תווים",
    statusPlaceholder: "בחר סטטוסים",
    resetFilters: "אפס מסננים",
    table: "טבלה",
    board: "לוח",
    noOrders: "אין הזמנות להצגה",
    export: "ייצא ל-CSV",
    parts: "חלקים",
    api: "API",
    user: "משתמש",
    processMany: "הכנס את ההזמנות המסומנות לתור",
    selectedOrder: "הזמנה 1 נבחרה",
    selectedOrders: "{{amount}} הזמנות נבחרו",
    processSuccess: "ההזמנות נכנסו לתור בהצלחה",
    processPartiallySuccess: "הפעולה הצליחה חלקית, אנא נסה שנית",
    processError: "הפעולה נכשלה",
    search: "Search",
    clear: "Clear"
  },
  orderBoard: {
    order: "הזמנה",
    delivery: "משלוח",
    packs: "חבילות",
    parts: "חלקים",
    part: "חלק",
    user: "משתמש",
  },
  order: {
    details: "פרטים",
    id: "מזהה הזמנה",
    packs: "חבילות",
    code: "קוד ליקוט הזמנה",
    operations: "פעולות",
    timeline: "ציר זמן",
    name: "שם",
    productName: "שם מוצר",
    packId: "מזהה חבילה",
    sku: "מזהה מוצר (SKU)",
    quantity: "כמות",
    collectedQuantity: "כמות שנאספה",
    pickup: "איסוף ידני",
    collect: "אסוף ידנית",
    shipping: "נסה שנית משלוח",
    totalProducts: "סה\"כ מוצרים",
    DeliveryRequested: "בקשת משלוח",
    PartialPacked: "ארוז חלקית",
    Pending: "ממתין",
    Approved: "מאושר",
    InProgress: "בתהליך",
    Paused: "מושהה",
    Packed: "ארוז",
    Queued: "בתור",
    OutOfStock: "אזל מהמלאי",
    PackedManually: "ארוז (ידני)",
    Delivering: "במשלוח",
    CollectedManually: "נאסף (ידני)",
    Collected: "נאסף",
    PartialCollected: "נאסף חלקית",
    Canceled: "בוטל",
    Failed: "נכשל",
    PackingFailed: "אריזה נכשלה",
    DeliveryFailed: "משלוח נכשל",
    ProcessingFailed: "עיבוד נכשל",
    Shipped: "נשלח",
    Archived: "בארכיון",
    ArchiveRequested: "בקשת ארכיון",
    noDelivery: "אין משלוח",
    RetryOrder: "נסה שנית",
    RetryShipping: "נסה שנית משלוח",
    ManualPickUp: "איסוף ידני",
    ManualDelivery: "משלוח ידני",
    NotifyFailure: "הודע על כשל",
    pickUpSuccess: "איסוף ידני נקבע בהצלחה",
    orderSuccess: "הזמנה נוצרה מחדש בהצלחה",
    shipmentSuccess: "משלוח נוצר מחדש בהצלחה",
    deliverySuccess: "משלוח ידני נקבע בהצלחה",
    failureSuccess: "כשל הועבר בהצלחה",
    cancelSuccess: "הזמנה בוטלה בהצלחה",
    queueSuccess: "הזמנה נוספה לתור בהצלחה",
    completeSuccess: "ההזמנה הושלמה בהצלחה",
    operationFail: "הפעולה נכשלה",
    cancel: "בטל",
    process: "עבד הזמנה",
    complete: "השלם",
    submit: "שלח",
    part: "חלק הזמנה",
    products: "מוצרים",
    source: "מקור",
    api: "API",
    user: "משתמש",
    confirmProcess: "האם אתה בטוח שהינך רוצה להכניס את ההזמנה לתור?",
    confirmCancel: "האם אתה בטוח שהינך רוצה לבטל את ההזמנה?",
    confirmComplete: "האם אתה בטוח שהינך רוצה להשלים את ההזמנה?",
    confirmManualDelivery: "האם אתה בטוח שהינך רוצה למסור את ההזמנה באופן ידני?",
  },
  shipping: {
    retryShipping: "נסה שנית משלוח",
    externalId: "מזהה חיצוני של החברה",
    delivery: "משלוח",
    verification: "קוד אימות",
    cancel: "בטל",
    submit: "שלח",
    name: "שם",
    enterName: "הזן שם",
    address: "כתובת",
    enterAddress: "הזן כתובת",
    notes: "הערות",
    enterNotes: "הזן הערות",
    phone: "מספר טלפון",
    retryShipmentSuccess: "משלוח נוצר מחדש בהצלחה",
    operationFail: "הפעולה נכשלה",
    shipmentTitle: "פרטי משלוח",
    completed: "נמסר",
    verificationCode: "קוד אימות",
    showLogs: "הצג לוגים",
    noShipment: "אין משלוח",
    attempts: "מספר ניסיונות",
    orderOperationsTitle: "פעולות הזמנה",
    shipmentOperationsTitle: "פעולות משלוח",
    noOperations: "אין פעולות זמינות כרגע",
    retryOrderTitle: "האם אתה בטוח שברצונך לנסות שנית את ההזמנה?",
    manualDeliveryTitle: "האם אתה בטוח שברצונך לבצע משלוח ידני?",
    manualPickupTitle: "האם אתה בטוח שברצונך לבצע איסוף ידני?",
    notifyFailureTitle: "האם אתה בטוח שברצונך להודיע על כשל?",
    retryOrderDescription: "לא תוכל לבטל פעולה זו",
    manualDeliveryDescription: "לא תוכל לבטל פעולה זו",
    manualPickupDescription: "לא תוכל לבטל פעולה זו",
    notifyFailureDescription: "זה יבטל את כל הפעולות",
    retryOrderOkText: "נסה שנית",
    manualDeliveryOkText: "בצע משלוח ידני",
    manualPickupOkText: "בצע איסוף ידני",
    notifyFailureOkText: "הודע",
    shipmentDetails: "פרטי משלוח",
    retry: "נסה שנית"
  },
  transfers: {
    title: "נתוני העברות",
    id: "מזהה",
    type: "סוג",
    quantity: "כמות",
    created: "נוצר",
    name: "שם",
    sku: "מזהה מוצר (SKU)",
    searchPlaceholder: "הזן מזהה מוצר להעברה לצפייה בפרטים",
    notFoundError: "מוצר להעברה עם מזהה {{id}} לא קיים"
  },
  maintenance: {
    id: "מזהה",
    key: "מפתח",
    in: "כניסה",
    out: "יציאה",
    started: "התחיל",
    ended: "הסתיים",
    title: "חידוש מלאי",
    name: "שם",
    sku: "מזהה מוצר (SKU)",
    diff: "הפרש",
    operation: "פעולה",
    operations: "פעולות",
    indexing: "אינדוקס",
    palletId: "מזהה משטח",
    transferId: "מזהה העברה",
    quantity: "כמות",
    compareTitle: "השוואת מלאי",
    report: "הורד דוח"
  },
  media: {
    front: "קדמי",
    back: "אחורי",
    kiosk: "קיוסק",
    lift: "מעלית",
    outdoor1: "חיצוני 1",
    outdoor2: "חיצוני 2",
    title: "שידורים חיים",
    hlsNotSupported: "הדפדפן הזה אינו תומך בשידור חי",
    id: "מזהה",
    from: "מ",
    to: "עד",
    mediaType: "סוג",
    status: "סטטוס",
    user: "משתמש",
    progress: "התקדמות",
    count: "ספירה",
    libraryTitle: "ספריית מדיה",
    createNew: "חדש +",
    createDrawerTitle: "צור מדיה חדשה",
    createKeyButton: "צור מדיה חדשה +",
    createSuccessTitle: "יששש!",
    createSuccessMessage: "המדיה נוצרה בהצלחה!",
    mediaTypeError: "נא לבחור סוג מדיה",
    timeRangeError: "טווח זמן התאריך לא צריך להיות יותר מ-5 דקות",
    dateError: "נא לבחור טווח תאריכים",
    create: "צור",
    cancel: "בטל",
    date: "תאריך",
    Images: "תמונות",
    Videos: "סרטונים",
    Pending: "ממתין",
    Processing: "מעובד",
    Uploading: "מעלה",
    Success: "הצלחה",
    Failed: "נכשל",
    streamsTip: "ניתן לבחור עד 2 מצלמות"
  },
  indexing: {
    id: "מזהה",
    trigger: "טריגר",
    status: "סטטוס",
    createdOn: "נוצר",
    listTitle: "בקשות אינדוקס",
    create: "צור",
    createTitle: "צור בקשת אינדוקס",
    maintenanceId: "מזהה תחזוקה",
    maintenancePlaceholder: "בחר תחזוקה",
    pallet: "משטח",
    palletPlaceholder: "בחר משטח",
    side: "צד",
    range: "טווח",
    confirm: "אשר",
    cancel: "בטל",
    remove: "הסר",
    createSuccessTitle: "הצלחה",
    createSuccessMessage: "בקשת אינדוקס נוצרה בהצלחה",
    pending: "ממתין",
    approved: "מאושר",
    inprogress: "בתהליך",
    completed: "הושלם",
    failed: "נכשל",
    createFail: "אירעה שגיאה ביצירת בקשת אינדוקס",
    compareTitle: "השוואת תוצאות אינדוקס",
    filterByPallet: "סנן לפי מזהה משטח",
    palletIdPlaceholder: "בחר מזהה משטח",
    tipBefore: "לפני אינדוקס",
    tipAfter: "לאחר אינדוקס",
    palletId: "מזהה משטח",
    cellID: "מזהה תא",
    sku: "מזהה מוצר (SKU)",
    quantity: "כמות",
    indexType: "סוג אינדוקס",
    unknown: "לא ידוע",
    unrecognized: "לא מוכר",
    indexed: "מאונדקס",
    missing: "חסר",
    totalQuantity: "סה\"כ כמות",
    rangesTitle: "טווחי אינדוקס",
    start: "התחלה",
    end: "סיום",
    wholeSystemIndexing: "אינדוקס המערכת כולה",
    delete: "מחק",
    createRange: "הוסף טווח",
    rangeTitle: "הוספת טווח חדש",
    ranges: "טווחים",
    rangeOne: "טווח",
    rangeMore: "טווחים",
    rangeTip: "יוספו בבקשת האינדוקס",
    rangeTipNoRanges: "נא להוסיף לפחות טווח אחד",
    row: "שורה",
    column: "עמודה",
    validity: "תקינות",
    error: "שגיאה",
    valid: "תקין",
    invalid: "לא תקין",
  },
  system: {
    stations: "תחנות",
    packerStock: "מלאי אורזים",
    temperature: "טמפרטורה",
    buffers: "מאגרי זיכרון",
    compressor: "מדחס",
    on: "מופעל",
    off: "כבוי",
    searchPlaceholder: "חפש לפי שם או מזהה מוצר (SKU)",
    name: "שם",
    sku: "מזהה מוצר (SKU)",
    totalQuantity: "סה\"כ כמות",
    availableQuantity: "זמין",
    assignedQuantity: "מוקצה",
    reservedQuantity: "שמור",
    enabled: "מופעל",
    total: "סה\"כ מוצרים",
    id: "מזהה",
    station: "תחנה",
    quantity: "כמות",
    stationTotalProducts: "סה\"כ",
    warning: "מידע המערכת אינו זמין כרגע",
    buffer: "מאגר",
    export: "ייצא ל-CSV"
  },
  upsState: {
    ratinVoltage: "מתח כניסה נומינלי",
    ratoutVoltage: "מתח יציאה נומינלי",
    battery: "סוללה",
    temperature: "טמפרטורה",
    source: "מקור יציאה",
    warning: "מידע ה-UPS אינו זמין כרגע"
  },
  productsPortfolio: {
    products: "מוצרים",
    name: "שם",
    category: "קטגוריה",
    categoryName: "שם קטגוריה",
    sku: "מזהה מוצר (SKU)",
    disabledSystems: "מערכות מושבתות",
    searchPlaceholder: "חפש לפי שם או מזהה מוצר (SKU)",
    categoriesPlaceholder: "כל הקטגוריות",
    disabledSystemsFilter: "רק מוצרים עם מערכות מושבתות",
    resetFilters: "אפס מסננים",
    productName: "שם מוצר",
    productImage: "שם מוצר",
    temperature: "טמפרטורה",
    frozen: "קפוא",
    fresh: "Fresh",
    chilled: "מצונן",
    cold: "קר",
    ambient: "לא מקורר",
    none: "ללא",
    external: "חיצוני",
    properties: "מאפיינים",
    weight: "משקל",
    size: "גודל",
    small: "קטן",
    medium: "בינוני",
    big: "גדול",
    create: "צור",
    namePlaceholder: "הזן שם מוצר",
    categoryNamePlaceholder: "הזן שם קטגוריה",
    dropdownPlaceholder: "נא לבחור",
    createTitle: "צור מוצר חדש",
    createProductButton: "מוצר חדש",
    requiredMessage: "שדה זה הינו חובה",
    createProductSuccess: "המוצר נוצר בהצלחה",
    createProductFail: "שגיאה ביצירת המוצר",
    updateProductSuccess: "המוצר עודכן בהצלחה",
    updateProductFail: "שגיאה בעדכון המוצר",
    approveProductSuccess: "המוצר אושר בהצלחה",
    approveProductFail: "שגיאה באישור המוצר",
    revertProductSuccess: "מוצר בוטל בהצלחה",
    revertProductFail: "שגיאה בביטול המוצר",
    approveProductsSuccess: "המוצרים אושרו בהצלחה",
    approveProductsFail: "שגיאה באישור המוצרים",
    declineProductSuccess: "המוצר נדחה בהצלחה",
    declineProductFail: "שגיאה בדחיית המוצר",
    optional: "(אופציונלי)",
    nameSuccess: "שם המוצר עודכן בהצלחה",
    nameError: "שגיאה בעדכון שם המוצר",
    categorySuccess: "קטגוריית המוצר עודכנה בהצלחה",
    categoryError: "שגיאה בעדכון קטגוריית המוצר",
    createCategoryError: "שגיאה ביצירת קטגוריית מוצר",
    noCategory: "ללא קטגוריה",
    skusTitle: "מזהי מוצר (SKU) של המוצר",
    addSku: "הוסף מזהה מוצר (SKU)",
    createCategory: "צור קטגוריה חדשה",
    skuNamePlaceholder: "הזן שם מזהה מוצר (SKU)",
    barCodes: "ברקודים",
    barCodesPlaceholder: "הזן ברקודים",
    barCodesAddPlaceholder: "הזן ברקוד להוספה",
    barCodesTip: "הפרד מספר ברקודים באמצעות פסיק",
    cancel: "בטל",
    save: "שמור",
    addSkuSuccess: "מזהה מוצר (SKU) נוסף בהצלחה למוצר",
    addSkuError: "שגיאה בהוספת מזהה מוצר (SKU) למוצר",
    addBarcodeSuccess: "ברקוד נוסף בהצלחה למזהה מוצר (SKU)",
    addBarcodeError: "שגיאה בהוספת ברקוד למזהה מוצר (SKU)",
    emptyBarcodeError: "הברקוד לא יכול להיות ריק",
    more: "עוד",
    status: "סטטוס",
    pending: "ממתין",
    approved: "מאושר",
    declined: "נדחה",
    approvePendingProducts: "אשר מוצרים ממתינים",
    details: "פרטים",
    productSkus: "מזהי מוצר (SKU) של המוצר",
    externalLabel: "אחסון חיצוני",
    enabled: "מופעל",
    saveChanges: "שמור שינויים",
    declineProduct: "דחה מוצר",
    revertProduct: "בטל מוצר",
    approveProduct: "אשר מוצר",
    statusesPlaceholder: "כל הסטטוסים",
    confirmation: "האם אתה בטוח?",
    export: "ייצא ל-CSV",
  },
  monitoring: {
    userPlaceholder: "חפש לפי משתמש",
    statusPlaceholder: "כל הסטטוסים",
    typePlaceholder: "כל הסוגים",
    resetFilters: "אפס מסננים",
    library: "ספריית מדיה",
    streams: "שידור חי",
    images: "תמונה",
    videos: "וידאו",
    Pending: "ממתין",
    Processing: "מעובד",
    Uploading: "מעלה",
    Success: "הצלחה",
    Failed: "נכשל",
    date: "תאריך",
    type: "סוג",
    status: "סטטוס",
    user: "משתמש",
    total: "סה\"כ",
    cancel: "בטל",
    request: "בקשה",
    addMedia: "הוסף מדיה",
    requestMedia: "בקש מדיה",
    chooseCamera: "בחר מצלמה",
    chooseCameraTip: "(עד 2)",
    minute: "דקה",
    minutes: "דקות",
    duration: "משך",
    time: "זמן",
    details: "פרטי בקשה",
    detailsPlaceholder: "מדוע אתה מבקש נתון ספציפי זה",
    typePlaceholderForm: "בחר סוג",
    durationPlaceholderForm: "בחר משך",
    requestTitle: "בקש מדיה",
    media: "מדיה",
    requiredError: "שדה חובה",
    requestsSuccess: "הבקשות נוספו בהצלחה",
    requestSuccess: "הבקשה נוספה בהצלחה",
    requestError: "שגיאה בהוספת הבקשות",
    front: "קדמי",
    back: "אחורי",
    kiosk: "קיוסק",
    lift: "מעלית",
    "outdoor-1": "חיצוני 1",
    "outdoor-2": "חיצוני 2",
    "ceiling-back": "תקרה אחורית",
    "ceiling-front": "תקרה קדמית",
    "ceiling-1": "תקרה 1",
    "ceiling-2": "תקרה 2",
    "ceiling-3": "תקרה 3",
    "ceiling-4": "תקרה 4",
    boxer: "בוקסר",
    outdoor: "חיצוני",
    hlsNotSupported: "הדפדפן הזה אינו תומך בשידור חי",
    modalTitle: "בחר מדיה",
    modalSubtitleVideo: "לחץ על הווידאו לצפייה בגודל מלא",
    modalSubtitleImage: "לחץ על התמונה לצפייה בגודל מלא",
    preview: "תצוגה מקדימה",
    mediaPreview: "תצוגה מקדימה של מדיה",
    noImages: "אין תמונות להצגה",
    noVideos: "אין וידאו להצגה"
  },
  store: {
    searchPlaceholder: "חפש לפי שם",
    cart: "עגלת קניות",
    resetFilters: "אפס מסננים",
    name: "שם",
    totalQuantity: "סה\"כ כמות",
    availableQuantity: "זמין",
    assignedQuantity: "מוקצה",
    reservedQuantity: "שמור",
    sku: "מזהה מוצר (SKU)",
    quantity: "כמות",
    add: "הוסף לעגלה",
    total: "סה\"כ",
    clear: "נקה הכל",
    create: "צור הזמנה",
    placeOrderSuccess: "ההזמנה נוצרה בהצלחה",
    placeOrderError: "שגיאה ביצירת ההזמנה"
  },
  control: {
    autoMode: "מצב אוטומטי",
    manualMode: "מצב ידני",
    switchToAuto: "עבור למצב אוטומטי",
    switchToManual: "עבור למצב ידני",
    searchPlaceholder: "חפש לפי שם",
    stream: "שידור חי",
    run: "הפעל",
    manualConfirmation: "האם אתה בטוח שברצונך לקחת שליטה?",
    confirm: "אשר",
    warning: "אתה לוקח שליטה",
    error: "שגיאה במעבר מצב",
    success: "המצב הוחלף לאוטומטי",
    autoModeEnabled: "מצב אוטומטי מופעל",
    commandSuccess: "הפקודה הופעלה בהצלחה",
    commandError: "שגיאה בהפעלת הפקודה"
  },
  support: {
    support: "תמיכה",
    pageTip: "נא לספק את המידע בנוגע למקרה שלך למטה, וצוות התמיכה שלנו יסייע לך בהקדם האפשרי.",
    subject: "נושא",
    subjectPlaceholder: "הזן את נושא המקרה שלך",
    description: "תיאור",
    descriptionPlaceholder: "תאר פרטים",
    submit: "שלח",
    successTitle: "המקרה נשלח בהצלחה!",
    successDescription: "צוות התמיכה יסייע לך בהקדם האפשרי. לקבלת סיוע נוסף, תוכל למצוא את פרטי הכרטיס בדוא\"ל שלך.",
    close: "סגור",
    modalDescription: "נא לספק את המידע בנוגע למקרה שלך למטה.",
    cancel: "בטל"
  },
  metadata: {
    filterName: "חפש לפי שם",
    filterRequired: "הצג רק חובה",
    filterClear: "נקה מסנן",
    entries: "רישומים",
    name: "שם",
    options: "אפשרויות",
    type: "סוג",
    required: "חובה",
    edit: "ערוך",
    delete: "מחק",
    Int: "מספר שלם",
    Bool: "בוליאני",
    String: "מחרוזת",
    Float: "מספר עשרוני",
    addNew: "הוסף רישום חדש",
    namePlaceholder: "הזן שם",
    typePlaceholder: "הזן סוג",
    optionsPlaceholder: "הוסף אפשרויות, מופרדות בפסיק",
    requiredLabel: "סמן כחובה",
    cancel: "בטל",
    add: "הוסף רישום",
    editDrawerTitle: "ערוך מטא נתונים",
    save: "שמור שינויים",
    createSuccessMessage: "הרישום נוצר בהצלחה!",
    createErrorMessage: "שגיאה ביצירת הרישום!",
    updateSuccessMessage: "הרישום עודכן בהצלחה!",
    updateErrorMessage: "שגיאה בעדכון הרישום!",
    deleteSuccessMessage: "הרישום נמחק בהצלחה!",
    deleteErrorMessage: "שגיאה במחיקת הרישום!",
    confirmationTitle: "אשר מחיקה",
    deletionConfirmation: "האם אתה בטוח שברצונך למחוק את הרישום?",
    confirm: "אשר",
    requiredMessage: "שדה זה הינו חובה"
  }
};